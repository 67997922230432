import React, { RefObject, useEffect, useImperativeHandle, useRef } from "react"
import moment from "moment"
import { useState } from "react"
import { Dialog, DialogContent, DialogTitle, DialogActions, createStyles, withStyles, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { CSSProperties } from "@material-ui/styles"
import { t } from './../../infrastructure/i18nextHelper'
import { defaultStyles, defaultColors, dialogCell, alignRight } from '../../infrastructure/materialUiThemeProvider'
import { UserContextContainer } from "../../infrastructure/signIn/userContext"
import { api } from '../../infrastructure/api'
import { ActualStockStatus, StockProjectionDetail, StockProjectionSiteDifference } from "./stockModels"
import { Button } from "../common/customComponents"
import { CommentButton } from '../common/comment/commentButton'
import { closedColor, frozenColor, openedColor } from "./actualStockEdit/accountingPeriod/accountingPeriodDialog"

export type StockProjectionDetailArgs = {
    date: string
    productIds: string[]
    sites: string[]
    dutyStatuss: string[]
    companys: string[]
}

type StockSiteStatus = {
    site: string
    status: string
}

type SiteStatus = Opened | Frozen | Closed | FullyCalibrated | Mixed | NotCalibrated
type Opened = 'opened'
type Frozen = 'frozen'
type Closed = 'closed'
type FullyCalibrated = 'fullyCalibrated'
type Mixed = 'mixed'
type NotCalibrated = 'notCalibrated'

function DetailDialog(props) {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [stockDetails, setStockDetails] = useState<StockProjectionDetail | null>(null)
    const [stockStatus, setStockStatus] = useState<StockSiteStatus[]>([])

    let user = UserContextContainer.useContainer()

    dialogRef = useRef<{ open: (args: StockProjectionDetailArgs) => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: async (args: StockProjectionDetailArgs) => {
            await fetchDetails(args)
            setOpenDialog(true)
        }
    }))

    let getTrad = (text: string) => t('stock.stockProjectionDetailsDialog.' + text)

    let fetchDetails = async (args: StockProjectionDetailArgs) => {
        let resultPromise = api.get<StockProjectionDetail>(`stock/projection/details?${prepareQuery()}`)
        let statusPromise = api.get<StockSiteStatus[]>(`stock/status?${statusQuery()}`)

        setStockDetails(await resultPromise)
        setStockStatus(await statusPromise)

        function prepareQuery() {
            let query = `date=${args.date.toString()}&`
            args.companys?.map(x => { query = query + `companys=${x}&` })
            args.dutyStatuss?.map(x => { query = query + `dutyStatuss=${x}&` })
            args.productIds?.map(x => { query = query + `productIds=${x}&` })
            args.sites?.map(x => { query = query + `sites=${x}&` })
            return query
        }

        function statusQuery() {
            let query = `country=${user.currentCountry}&date=${args.date.toString()}&`
            args.sites?.map(x => { query = query + `sites=${x}&` })
            return query
        }
    }

    let getStatusClass = (status: SiteStatus) => {
        switch (status) {
            case 'opened':
                return props.classes.opened
            case 'frozen':
                return props.classes.frozen
            case 'closed':
                return props.classes.closed
            case 'notCalibrated':
                return props.classes.notCalibrated
            case 'mixed':
                return props.classes.mixed
            case 'fullyCalibrated':
                return props.classes.fullyCalibrated
        }
    }

    let close = () => {
        setStockDetails(null)
        setOpenDialog(false)
    }

    return (
        <Dialog
            open={openDialog}
            onClose={close}
            scroll={'paper'}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'>
            <DialogTitle id='scroll-dialog-title'>{getTrad('title')}</DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Line classes={props.classes} title={getTrad('date')} value={moment(stockDetails?.date).format('MM/DD/YYYY')} />
                    <Line classes={props.classes} title={getTrad('products')} value={stockDetails?.productCodes} />
                    <Line classes={props.classes} title={getTrad('dutyStatuss')} value={stockDetails?.dutyStatuss} />
                    <Line classes={props.classes} title={getTrad('companys')} value={stockDetails?.companys} />
                    <GlobalStatus classes={props.classes}
                        detailValues={stockDetails?.values}
                        stockSiteStatuss={stockStatus}
                        getStatusClass={getStatusClass} />
                    <DetailTable classes={props.classes} stockDetails={stockDetails} siteStatuss={stockStatus}
                        hasUntriggeredQuantity={stockDetails?.values.some(x => x.untriggeredQuantity)}
                        hasGlobalCapacity={stockDetails?.values.some(x => x.globalCapacity)} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={props.classes.closeButton}
                    label={t('history.close')}
                    onClick={() => setOpenDialog(false)}
                    color='primary' />
            </DialogActions>
        </Dialog>)
}

export type LineProps = {
    title: string
    value?: string
    classes: any
}
export function Line(props: LineProps) {
    return (
        <div className={props.classes.line}>
            <Typography className={props.classes.title}>{props.title}</Typography>
            <Typography className={props.classes.value}>{props.value}</Typography>
        </div>
    )
}

type GlobalStatusProps = {
    detailValues?: StockProjectionSiteDifference[]
    stockSiteStatuss: StockSiteStatus[]
    getStatusClass: (status: SiteStatus) => any
    classes: any
}
function GlobalStatus(props: GlobalStatusProps) {
    let [status, setStatus] = useState<SiteStatus>('notCalibrated')

    let getTrad = (text: string) => t('stock.stockProjectionDetailsDialog.' + text)

    let globalStatus = (): SiteStatus => {
        let allStockStatus = props.stockSiteStatuss.map(x => x.status).distinct()
        let allEmptyStatuses = allStockStatus.length == 1 && !allStockStatus[0]
        let statuses = props.detailValues?.map(x => x.actualStockStatus).distinct()

        let allCalibrated = statuses?.length == 1 && statuses[0] == 'FullyCalibrated'
        if (allCalibrated) return 'fullyCalibrated'

        if (allEmptyStatuses) {
            let noneCalibrated = statuses?.length == 1 && statuses[0] == 'NotCalibrated'
            if (noneCalibrated) return 'notCalibrated'

            return 'mixed'
        }

        let someOpenedPeriods = props.stockSiteStatuss.findIndex(x => x.status == 'Opened') != -1
        if (someOpenedPeriods) return 'opened'

        let someFrozenPeriods = props.stockSiteStatuss.findIndex(x => x.status == 'Frozen') != -1
        if (someFrozenPeriods) return 'frozen'

        return 'closed'
    }

    useEffect(() => setStatus(globalStatus()), [])

    return (
        <div className={props.classes.line}>
            <Typography className={props.classes.title}>{getTrad('status')}</Typography>
            <Typography className={props.getStatusClass(status)}>{getTrad(`stockStatus.${status}`)}</Typography>
        </div>
    )
}

type DetailTableProps = {
    stockDetails: StockProjectionDetail | null
    siteStatuss: StockSiteStatus[]
    hasUntriggeredQuantity?: boolean
    hasGlobalCapacity?: boolean
    classes: any
}
function DetailTable(props: DetailTableProps) {

    let getTrad = (text: string) => t('stock.stockProjectionDetailsDialog.' + text)
    let getAssociatedLabel = () => t('stock.label.associatedQuantity.' + props.stockDetails?.associatedKind)

    let getDifferenceClassName = (difference: number | undefined) =>
        shouldWarnDifference(difference)
            ? props.classes.differenceWarning
            : isDifferenceCorrect(difference)
                ? props.classes.differenceCorrect
                : ''

    let getActualClassName = (actualStockStatus: ActualStockStatus | undefined, site: string) => {
        let siteStatus = props.siteStatuss.find(x => x.site == site)?.status

        return actualStockStatus === 'FullyCalibrated'
            ? props.classes.fullyCalibrated
            : siteStatus == 'Opened'
                ? props.classes.opened
                : siteStatus == 'Frozen'
                    ? props.classes.frozen
                    : siteStatus == 'Closed'
                        ? props.classes.closed
                        : actualStockStatus === 'Mixed'
                            ? props.classes.mixed
                            : props.classes.notCalibrated
    }

    let shouldWarnDifference = (difference: number | undefined): boolean =>
        (difference && (difference > 100 || difference < -100)) ? true : false

    let isDifferenceCorrect = (difference: number | undefined): boolean =>
        difference === 0 || (difference && difference > -100 && difference < 100) ? true : false

    let formatCommentAssociationKey = (site: string): string => {
        let formatedDate = moment(props.stockDetails?.date).add(1, "d").format('YYYY/MM/DD')
        return `actualStock-${site}-${formatedDate}`
    }

    let values = props.stockDetails?.values!

    let totalProjected = roundedSum<StockProjectionSiteDifference>(values, x => x.projected)
    let totalActual = roundedSum<StockProjectionSiteDifference>(values, x => x.actual)
    let totalDifference = roundedSum<StockProjectionSiteDifference>(values, x => x.difference)
    let totalMinVolume = roundedSum<StockProjectionSiteDifference>(values, x => x.siteCapacity.minVolume)
    let totalLowVolume = roundedSum<StockProjectionSiteDifference>(values, x => x.siteCapacity.targetLow)
    let totalHighVolume = roundedSum<StockProjectionSiteDifference>(values, x => x.siteCapacity.targetHigh)
    let totalMaxVolume = roundedSum<StockProjectionSiteDifference>(values, x => x.siteCapacity.maxVolume)
    let totalAssociated = roundedSum<StockProjectionSiteDifference>(values, x => x.associated)

    if (!props.stockDetails) return (<></>)

    return (
        <Table className={props.classes?.table}>
            <TableHead>
                <TableRow>
                    <TableCell className={props.classes.cell}></TableCell>
                    <TableCell className={props.classes.cell}>{getTrad('projected')}</TableCell>
                    <TableCell className={props.classes.cell}>{getTrad('actual')}</TableCell>
                    <TableCell className={props.classes.cell}>{getTrad('difference')}</TableCell>
                    <TableCell className={props.classes.cell}>{getTrad('min')}</TableCell>
                    <TableCell className={props.classes.cell}>{getTrad('low')}</TableCell>
                    <TableCell className={props.classes.cell}>{getTrad('high')}</TableCell>
                    <TableCell className={props.classes.cell}>{getTrad('max')}</TableCell>
                    {props.hasUntriggeredQuantity && <TableCell className={props.classes.cell}>{getTrad('untriggeredQuantity')}</TableCell>}
                    {props.hasGlobalCapacity && <TableCell className={props.classes.cell}>{getTrad('globalCapacity')}</TableCell>}
                    {props.stockDetails.associatedKind && <TableCell className={props.classes.cell}>{getAssociatedLabel()}</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.stockDetails.values.map((x, index) => (
                    <TableRow key={index} className={props.classes.row}>
                        <TableCell className={props.classes.cell}>{x.siteName}</TableCell>
                        <TableCell className={props.classes.cellRight}>{x.projected}</TableCell>
                        <TableCell className={props.classes.cellRight + ' ' + getActualClassName(x.actualStockStatus, x.site) + ' ' + props.classes.cellWithComment}>
                            <div>
                                <CommentButton
                                    associationKey={formatCommentAssociationKey(x.site)}
                                    className={props.classes.showOnHover}
                                />
                            </div>
                            <span className={props.classes.actualStockValue}>{x.actual}</span>
                        </TableCell>
                        <TableCell className={props.classes.cellRight + ' ' + getDifferenceClassName(x.difference)}>{x.difference}</TableCell>
                        <TableCell className={props.classes.cellRight}>{x.siteCapacity.minVolume}</TableCell>
                        <TableCell className={props.classes.cellRight}>{x.siteCapacity.targetLow}</TableCell>
                        <TableCell className={props.classes.cellRight}>{x.siteCapacity.targetHigh}</TableCell>
                        <TableCell className={props.classes.cellRight}>{x.siteCapacity.maxVolume}</TableCell>
                        {props.hasUntriggeredQuantity && <TableCell className={props.classes.cellRight}>{x.untriggeredQuantity}</TableCell>}
                        {props.hasGlobalCapacity && <TableCell className={props.classes.cellRight}>{x.globalCapacity}</TableCell>}
                        {props.stockDetails?.associatedKind && <TableCell className={props.classes.cellRight}>{x.associated}</TableCell>}
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell className={props.classes.cell + ' ' + props.classes.totalCell}>{getTrad('total')}</TableCell>
                    <TableCell className={props.classes.cellRight + ' ' + props.classes.totalCell}>{totalProjected}</TableCell>
                    <TableCell className={props.classes.cellRight + ' ' + props.classes.totalCell}>{totalActual}</TableCell>
                    <TableCell className={props.classes.cellRight + ' ' + props.classes.totalCell}>{totalDifference}</TableCell>
                    <TableCell className={props.classes.cellRight + ' ' + props.classes.totalCell}>{totalMinVolume}</TableCell>
                    <TableCell className={props.classes.cellRight + ' ' + props.classes.totalCell}>{totalLowVolume}</TableCell>
                    <TableCell className={props.classes.cellRight + ' ' + props.classes.totalCell}>{totalHighVolume}</TableCell>
                    <TableCell className={props.classes.cellRight + ' ' + props.classes.totalCell}>{totalMaxVolume}</TableCell>
                    {props.hasUntriggeredQuantity && <TableCell />}
                    {props.hasGlobalCapacity && <TableCell />}
                    {props.stockDetails.associatedKind && <TableCell className={props.classes.cellRight + ' ' + props.classes.totalCell}>{totalAssociated}</TableCell>}
                </TableRow>
            </TableBody>
        </Table>)
}

export function roundedSum<T>(values: T[] | undefined, property: (detail: T) => (number | null | undefined)) {
    if (!values) return undefined;
    let propertyValues = values.map(x => property(x) ?? 0)
    let precisions = propertyValues?.map(x => (x + '').split('.')[1]?.length ?? 0) ?? [0]
    let precision = Math.max(...precisions)
    let result = propertyValues?.reduce((acc, curr) => acc + curr, 0)
    return result ? parseFloat(result.toFixed(precision)) : undefined
}

let cell: CSSProperties = dialogCell
let cellRight: CSSProperties = { ...cell, ...alignRight }

let styles = () => createStyles({
    cell, cellRight,
    title: { marginRight: '0.5em' },
    value: { fontWeight: 'lighter' },
    line: { ...defaultStyles.flexRow },
    alignRight: { textAlign: 'right' },
    differenceWarning: {
        color: defaultColors.red.light.color,
        fontWeight: 'bold'
    },
    differenceCorrect: {
        color: defaultColors.green.light.color,
        fontWeight: 'bold'
    },
    cellWithComment: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    actualStockValue: {
        marginRight: '0.5em'
    },
    showOnHover: {
        opacity: 0
    },
    row: {
        '&:hover $showOnHover': { opacity: 1 }
    },
    totalCell: {
        fontWeight: 'bold'
    },
    opened: {
        fontWeight: 'bold',
        color: openedColor
    },
    frozen: {
        fontWeight: 'bold',
        color: frozenColor
    },
    closed: {
        fontWeight: 'bold',
        color: closedColor
    },
    notCalibrated: {
        fontWeight: 'bold',
        color: defaultColors.grey.dark.color
    },
    mixed: {
        fontWeight: 'bold',
        color: defaultColors.orange.light.color
    },
    fullyCalibrated: {
        fontWeight: 'bold',
        color: defaultColors.lightBlue.light.color
    }
})

let dialogRef: RefObject<{ open: (args: StockProjectionDetailArgs) => void }> | null = null

export let stockProjectionDetailDialog = { open: (args: StockProjectionDetailArgs) => dialogRef?.current?.open(args) }
export let StockProjectionDetailDialog = withStyles(styles)(DetailDialog)