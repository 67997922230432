import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { withStyles, createStyles, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import { hasFeature } from '../../../infrastructure/feature'
import { SnackbarContainer } from '../../../infrastructure/snackbars'
import { reportStyles, ReportShell } from './_common'
import { Button, DatePicker, MonthPicker } from '../../common/customComponents'

function _primo({ classes }: MuiProps) {
    let [forecastStart, setForecastStart] = useState<string | null>(null)
    let [fromMonth, setFromMonth] = useState<string | null>(moment().startOf('month').format('YYYY-MM-DD'))
    let [toMonth, setToMonth] = useState<string | null>(moment().add(3, 'months').endOf('month').format('YYYY-MM-DD'))
    let [withSingleFlows, setWithSingleFlows] = useState<boolean>(true)
    let [withAggregatedFlows, setWithAggregatedFlows] = useState<boolean>(true)

    let snackbar = SnackbarContainer.useContainer()

    let generatePrimo = async () => {
        if (!withSingleFlows && !withAggregatedFlows) {
            snackbar.info(t('report.primo.noExportWithoutSelection'))
            return
        }

        let filename = (await api.get<{ filename: string }>('report/primo/filename')).filename

        await api.post('report/primo', { fromMonth, toMonth, forecastStartDate: forecastStart, filename, withSingleFlows, withAggregatedFlows })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    let load = async () => {
        let { date } = await api.get<{ date }>('stock/movement/mktsale/forecastStart')
        setForecastStart(date)
    }

    useEffect(() => { load() }, [])

    return (
        <ReportShell title={t('report.primo.name')} displayError={false}>
            <div className={classes.form}>
                <Button className={classes.generateButton}
                    label={t('report.export')}
                    img='/static/images/excel_red.svg'
                    onClick={generatePrimo} />
            </div>
            <div className={classes.dates}>
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox style={{ color: defaultColors.red.main.color }} checked={withSingleFlows} onChange={() => setWithSingleFlows(!withSingleFlows)} />}
                        label={t('report.primo.withSingleFlows')} />
                    <FormControlLabel
                        control={<Checkbox style={{ color: defaultColors.red.main.color }} checked={withAggregatedFlows} onChange={() => setWithAggregatedFlows(!withAggregatedFlows)} />}
                        label={t('report.primo.withAggregatedFlows')} />
                </FormGroup>
                {hasFeature('MarketRiskPastForecast') &&
                    <DatePicker classesOverride={{ datepicker: classes.datePicker }} date={forecastStart}
                        label={t('report.forecastStart')} setDate={newDate => setForecastStart(newDate)} />}
                <MonthPicker classesOverride={{ datepicker: classes.datePicker }} date={fromMonth}
                    label={t('report.fromMonth')} setDate={setFromMonth} />
                <MonthPicker classesOverride={{ datepicker: classes.datePicker }} date={toMonth}
                    label={t('report.toMonth')} setDate={setToMonth} />
            </div>
        </ReportShell>
    )
}

let styles = theme => createStyles({
    generateButton: defaultStyles.secondaryButton,
    datePicker: {
        marginLeft: '1em'
    },
    form: reportStyles.form,
    selectors: defaultStyles.flexColumn,
    dates: {
        ...defaultStyles.flexRow,
        width: '80%'
    }
})

export default withStyles(styles, muiOptions)(_primo)