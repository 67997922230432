import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import moment from 'moment'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, DatePicker, NumberField, Select, TableItem, TextField } from '../../common/customComponents'
import { Company, Product, Site } from './models'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'

type Date = string

type PhysicalStock = {
    id: Guid,
    company: string,
    productId: Guid,
    site: string,
    minVolume: number | null,
    maxVolume: number | null,
    start: Date | null,
    targetLow: number | null,
    targetHigh: number | null,
    createdAt: Date | null,
    changedAt: Date | null,
    changedBy: string | null
}

let emptyPhysicalStock = (): PhysicalStock => {
    return {
        id: guid.createNew(),
        company: '',
        productId: guid.empty,
        site: '',
        minVolume: null,
        maxVolume: null,
        start: null,
        targetLow: null,
        targetHigh: null,
        createdAt: null,
        changedAt: null,
        changedBy: null
    }
}

let toTableItem = (physicalStock: PhysicalStock): TableItem<MasterDataItem<PhysicalStock>> => {
    return {
        id: physicalStock.id,
        company: physicalStock.company,
        productId: physicalStock.productId,
        site: physicalStock.site,
        minVolume: physicalStock.minVolume,
        maxVolume: physicalStock.maxVolume,
        start: physicalStock.start,
        isModified: false,
        targetLow: physicalStock.targetLow,
        targetHigh: physicalStock.targetHigh,
        createdAt: physicalStock.createdAt,
        changedAt: physicalStock.changedAt,
        changedBy: physicalStock.changedBy
    }
}

type PhysicalStockFilter = {
    company: string | null,
    product: string | null,
    site: string | null,
    start: Date | null
}

let noFilters: PhysicalStockFilter = {
    company: null,
    product: null,
    site: null,
    start: null
}

let applyFilters = (physicalStocks: PhysicalStock[], filters: PhysicalStockFilter, companys: Company[], products: Product[], sites: Site[]): PhysicalStock[] => {
    let getProductCodeFromId = (id: Guid): string => products.find(x => x.id == id)?.code ?? ''
    let getCompanyNameFromCode = (code: string): string => companys.find(x => x.code == code)?.name ?? ''
    let getSiteNameFromCode = (code: string): string => sites.find(x => x.code == code)?.name ?? ''

    if (filters.company)
        physicalStocks = physicalStocks.filter(x => x.company.toLowerCase().contains(filters.company!.toLowerCase()) ||
            getCompanyNameFromCode(x.company).toLowerCase().contains(filters.company!.toLowerCase()))
    if (filters.product)
        physicalStocks = physicalStocks.filter(x => getProductCodeFromId(x.productId).toLowerCase().contains(filters.product!.toLowerCase()))
    if (filters.site)
        physicalStocks = physicalStocks.filter(x => getSiteNameFromCode(x.site).toLowerCase().contains(filters.site!.toLowerCase()))
    if (filters.start)
        physicalStocks = physicalStocks.filter(x => moment(x.start).isSameOrAfter(moment(filters.start)))
    return physicalStocks
}

function PhysicalStockMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [physicalStocks, setPhysicalStocks] = useState<TableItem<PhysicalStock>[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [products, setProducts] = useState<Product[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [fromDate, setFromDate] = useState<string | null>('')
    let [filters, setFilters] = useState<PhysicalStockFilter>(noFilters)

    let getProductCodeFromId = (id: Guid): string => products.find(x => x.id == id)?.code ?? ''
    let getCompanyNameFromCode = (code: string): string => companys.find(x => x.code == code)?.name ?? ''
    let getSiteNameFromCode = (code: string): string => sites.find(x => x.code == code)?.name ?? ''

    let load = async () => {
        let companyPromise = api.get<Company[]>('masterdata/physicalStock/company')
        let productPromise = api.get<Product[]>('masterdata/physicalStock/product')
        let sitePromise = api.get<Site[]>('masterdata/physicalStock/site')

        let query = ''
        let companys = await companyPromise
        companys.map(x => x.code).forEach(x => query += `companys=${x}&`)

        let stockPromise = api.get<TableItem<PhysicalStock>[]>('masterdata/physicalStock?' + query)

        setCompanys(companys)
        setProducts(await productPromise)
        setSites(await sitePromise)
        setPhysicalStocks(await stockPromise)
    }

    useEffect(() => {
        let effect = async () => {
            await load()
        }
        effect()
    }, [])

    useEffect(() => {
        setFilters({ ...filters, start: fromDate })
    }, [fromDate])

    let onSave = async (item: PhysicalStock) => {
        await api.post('masterdata/physicalStock', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        await api.del('masterdata/physicalStock', { toRemoveIds: ids })
        await load()
        return true
    }

    let getItems = () => applyFilters(physicalStocks, filters, companys, products, sites).map(toTableItem)

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'PhysicalStock.xlsx',
            sheets: [{ name: 'PhysicalStock', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onDateFilter = (newDate: string | null) => {
        setFromDate(newDate)
    }

    let onImportExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/physicalStock/import'
        api.upload(uploadUrl, file, 'physicalStock', { withReport: 'dialog' }).then(_ => load())
    }

    let getUsefulCapacity = (minVolume: any, maxVolume: any): string => {
        if (!minVolume || !maxVolume) return ''
        return (maxVolume - minVolume).toFixed(2)
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<PhysicalStock>>>[] = [
        {
            name: t('admin.masterdata.physicalStock.company'), value: x => getCompanyNameFromCode(x.company),
            columnFilter: { value: filters.company ?? '', onChange: (company: string) => setFilters({ ...filters, company: company }) }
        },
        {
            name: t('admin.masterdata.physicalStock.product'), value: x => getProductCodeFromId(x.productId),
            columnFilter: { value: filters.product ?? '', onChange: (product: string) => setFilters({ ...filters, product: product }) }
        },
        {
            name: t('admin.masterdata.physicalStock.site'), value: x => getSiteNameFromCode(x.site), valueForExport: x => x.site,
            columnFilter: { value: filters.site ?? '', onChange: (site: string) => setFilters({ ...filters, site: site }) }
        },
        { name: t('admin.masterdata.physicalStock.min'), value: x => x.minVolume, type: 'number' },
        { name: t('admin.masterdata.physicalStock.low'), value: x => x.targetLow, type: 'number' },
        { name: t('admin.masterdata.physicalStock.high'), value: x => x.targetHigh, type: 'number' },
        { name: t('admin.masterdata.physicalStock.max'), value: x => x.maxVolume, type: 'number' },
        { name: t('admin.masterdata.physicalStock.usefulCapacity'), value: x => getUsefulCapacity(x.minVolume, x.maxVolume), type: 'number' },
        { name: t('admin.masterdata.physicalStock.start'), value: x => moment(x.start).format('MM/DD/YYYY'), type: 'stringDate' },
    ]

    let formatDate = (date) =>
        new Intl.DateTimeFormat('en', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })
            .format(new Date(date))

    let getTooltipText = (physicalStock: PhysicalStock) =>
        physicalStock.changedBy ? `Changed by ${physicalStock.changedBy} at ${formatDate(physicalStock.changedAt)}` : null

    columns.forEach((col) => {
        col.tooltipValue = getTooltipText
    })

    let isManager = hasClaim(Claims.MasterdataPhysicalStockManager)

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'physical-stock-table'}
                headerLabel={t('admin.masterdata.physicalStock.physicalStocks')}
                itemLabel={t('admin.masterdata.physicalStock.physicalStock')}
                isManager={isManager}
                dateFilter={filters.start}
                onExportExcel={exportExcel}
                onDateFilter={onDateFilter}
                onImportExcel={onImportExcel}
                onNew={emptyPhysicalStock}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t('admin.masterdata.physicalStock.company')}
                                disabled={!isManager}
                                value={selectedItem.company}
                                choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: val }) }} />
                            <Select label={t('admin.masterdata.physicalStock.product')}
                                disabled={!isManager}
                                value={selectedItem.productId}
                                choices={products.map(x => ({ value: x.id, text: x.code }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, productId: val }) }} />
                            <Select label={t('admin.masterdata.physicalStock.site')}
                                disabled={!isManager}
                                value={selectedItem.site}
                                choices={sites.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, site: val }) }} />
                            <NumberField label={t('admin.masterdata.physicalStock.min')}
                                text={selectedItem.minVolume ? selectedItem.minVolume : null}
                                onChange={x => setSelectedItem({ ...selectedItem, minVolume: x })} />
                            <NumberField label={t('admin.masterdata.physicalStock.low')}
                                text={selectedItem.targetLow ? selectedItem.targetLow : null}
                                onChange={x => setSelectedItem({ ...selectedItem, targetLow: x })} />
                            <NumberField label={t('admin.masterdata.physicalStock.high')}
                                text={selectedItem.targetHigh ? selectedItem.targetHigh : null}
                                onChange={x => setSelectedItem({ ...selectedItem, targetHigh: x })} />
                            <NumberField label={t('admin.masterdata.physicalStock.max')}
                                text={selectedItem.maxVolume ? selectedItem.maxVolume : null}
                                onChange={x => setSelectedItem({ ...selectedItem, maxVolume: x })} />
                            <TextField label={t('admin.masterdata.physicalStock.usefulCapacity')}
                                disabled={true}
                                text={getUsefulCapacity(selectedItem.minVolume, selectedItem.maxVolume)} />
                            <DatePicker date={selectedItem.start}
                                label={t('admin.masterdata.physicalStock.start')}
                                setDate={newDate => setSelectedItem({ ...selectedItem, start: newDate })} />
                        </>
                    )}
            </MasterDataShell>
        </div>)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        inStockProjectionContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        },
    })

export default withStyles(styles, muiOptions)(PhysicalStockMasterData)