import React, { useState } from 'react'
import { createStyles, withStyles, Typography, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Paper, IconButton } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { muiOptions, defaultColors, customStyledComponent, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { hasFeature } from '../../../infrastructure/feature'
import { StockProduct, StockInputValue, Company, SiteCapacity } from '../stockModels'
import { NumberField } from '../../common/customComponents'
import { ActualStockContainer } from './actualStockStore'
import { Guid } from '@guid'

let cellStyle = {
    padding: '0.3em',
    paddingBottom: '0.6em',
    textAlign: 'center',
    maxWidth: '5rem'
}

let Cell = customStyledComponent(TableCell)(cellStyle, muiOptions)

let tBase = 'stock.label.stocks.'

function ActualStockTable({ classes }: MuiProps) {
    return (
        <Paper>
            <Table className={classes.tableMargin}>
                <ActualStockTableHeader classes={classes} />
                <ActualStockTableBody classes={classes} />
            </Table>
        </Paper>
    )
}

function ActualStockTableHeader({ classes }: MuiProps) {
    let store = ActualStockContainer.useContainer()
    return (
        <TableHead className={classes.tableHead + ' ' + classes.stickyHeader}>
            <TableRow>
                <Cell><Typography variant="h6" display="block">{t('stock.label.stocks.company')}</Typography></Cell>
                <Cell />
                <Cell />
                {store.availableProducts.map(x =>
                    <Cell key={x.id} align={'center'} colSpan={2}>
                        <Typography variant="h6" display="block">{`${x.code} (${x.unit})`}</Typography>
                    </Cell>
                )}
            </TableRow>
        </TableHead>
    )
}

function ActualStockTableBody({ classes }: MuiProps) {
    let store = ActualStockContainer.useContainer()

    return (
        <TableBody>
            {store.availableCompanies.map(c => (<CompanyTableRow key={c.code} classes={classes} company={c} />))}
        </TableBody>
    )
}

type CompanyTableRowProps = {
    company: Company
}

function CompanyTableRow({ classes, company }: CompanyTableRowProps & MuiProps) {
    let store = ActualStockContainer.useContainer()
    let [open, setOpen] = useState<boolean>(true)

    let isCalibrated = store.stockInput.values.filter(x => x.company === company.code).every(x => x.isCalibration)

    let site = store.sites.find(x => x.code == store.stockInput.site)
    let allocatedQuantity = site?.allocatedQuantity ?? null
    let unusableQuantity = site?.unusableQuantity ?? null

    let shouldDisableLine = (dutyStatus: string) => {
        return store.siteCompanyProducts.findIndex(x => x.company === company.name
            && x.dutyStatus === dutyStatus) < 0
    }

    return (
        <>
            <TableRow>
                <Cell>
                    <Typography className={isCalibrated ? classes.companyNameCalibrated : classes.companyName}
                        variant="h6" display="block">{company.name}
                    </Typography>
                </Cell>
                <Cell />
                <Cell />
                {
                    store.availableProducts.map(x =>
                        <Cell key={x.code + 'emptyCell'} colSpan={2} />
                    )
                }
            </TableRow>
            <TableRow>
                <Cell>
                    <Typography className={classes.companyName + ' ' + classes.customSubtitle1}
                        variant="subtitle1" display="block">{t(tBase + 'stocks')}
                    </Typography>
                </Cell>
                <Cell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Cell>
                <Cell />
                {
                    store.availableProducts.map(x =>
                        <Cell key={x.code + 'emptyCell'} colSpan={2} />
                    )
                }
            </TableRow>
            {open && <ProjectedActualTableRow classes={classes} company={company} opening={true} />}
            {open && !hasFeature('OpeningActualStock') && <ProjectedActualTableRow classes={classes} company={company} opening={false} />}

            <EmptyTableRow />

            {open && company.dutyStatuses.filter(x => !shouldDisableLine(x))
                .map((x, i) =>
                (<CompanyProductDutyStatusTableRow
                    key={i}
                    classes={classes}
                    company={company}
                    dutyStatus={x}
                    isCalibrated={isCalibrated}
                    allocatedQuantity={allocatedQuantity}
                    unusableQuantity={unusableQuantity} />))
            }
            <CapacitiesTableRow classes={classes} company={company} />
        </>
    )
}

type CapacitiesTableRowProps = {
    company: Company
}

let CapacitiesTableRow = ({ classes, company }: CapacitiesTableRowProps & MuiProps) => {
    let store = ActualStockContainer.useContainer()

    let [open, setOpen] = useState<boolean>(false)

    let safeSubstraction = (a: number | null | undefined, b: number | null | undefined) => a == null || b == null ? null : a - b

    let setSiteCapacitys = (product: Guid, value: number | null, property: keyof SiteCapacity) => {
        store.setStockInput({
            ...store.stockInput,
            siteCapacitys: store.stockInput.siteCapacitys.map(x => x.company == company.code && x.productId == product ? { ...x, [property]: value } : x)
        })
    }

    let findSiteCapacity = (product: Guid) => store.stockInput.siteCapacitys.find(x => x.company == company.code && x.productId == product)
    let findGlobalCapacity = (product: Guid) => store.stockInput.globalSiteCapacitys.find(x => x.productId == product)

    let getCompanyDeadStock = (product: Guid) => findSiteCapacity(product)?.minVolume
    let setCompanyDeadStock = (product: Guid, value: number) => setSiteCapacitys(product, value, 'minVolume')

    let getCompanyTargetHigh = (product: Guid) => findSiteCapacity(product)?.targetHigh
    let setCompanyTargetHigh = (product: Guid, value: number) => setSiteCapacitys(product, value, 'targetHigh')

    let getCompanyTargetLow = (product: Guid) => findSiteCapacity(product)?.targetLow
    let setCompanyTargetLow = (product: Guid, value: number) => setSiteCapacitys(product, value, 'targetLow')

    let getCompanyCapacity = (product: Guid) => findSiteCapacity(product)?.maxVolume
    let setCompanyCapacity = (product: Guid, value: number) => setSiteCapacitys(product, value, 'maxVolume')

    let getGlobalCapacity = (product: Guid) => findGlobalCapacity(product)?.value
    let getCompanyUsefulCapacity = (product: Guid) => safeSubstraction(getCompanyCapacity(product), getCompanyDeadStock(product))

    function* cellMetadatas(): Generator<{
        getValue: ((product: Guid) => number | null | undefined),
        onChange?: (product: Guid, value: number | null) => void,
        fractionDigits?: number,
        label: string
    }> {
        yield { getValue: getCompanyDeadStock, label: t(tBase + 'deadStock'), onChange: setCompanyDeadStock }
        yield { getValue: getCompanyTargetLow, label: t(tBase + 'targetLow'), onChange: setCompanyTargetLow }
        yield { getValue: getCompanyTargetHigh, label: t(tBase + 'targetHigh'), onChange: setCompanyTargetHigh }
        yield { getValue: getCompanyCapacity, label: t(tBase + 'maxCapacity'), onChange: setCompanyCapacity }

        if (hasFeature('ActualStockGlobalCapacity'))
            yield { getValue: getGlobalCapacity, label: t(tBase + 'globalCapacity') }

        yield { getValue: getCompanyUsefulCapacity, label: t(tBase + 'usefulCapacity'), fractionDigits: 2 }
    }

    return (
        <>
            <TableRow>
                <Cell>
                    <Typography className={classes.companyName + ' ' + classes.customSubtitle1}
                        variant="subtitle1" display="block">{t(tBase + 'capacities')}
                    </Typography>
                </Cell>
                <Cell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Cell>
                <Cell />
                {
                    store.availableProducts.map(x =>
                        <Cell key={x.code + 'emptyCell'} colSpan={2} />
                    )
                }
            </TableRow>
            {open && [...cellMetadatas()].map(x => {
                return (<TableRow key={company.code + x.label}>
                    <Cell />
                    <Cell className={classes.capacity}>
                        <Typography className={classes.companyName + ' ' + classes.customSubtitle2} variant="subtitle2" color="initial" display="inline">{x.label}</Typography>
                    </Cell>
                    <Cell />
                    {store.availableProducts.map(p =>
                        <React.Fragment key={p.code}>
                            <Cell />
                            <Cell>
                                {(findSiteCapacity(p.id) != null) &&
                                    <NumberField size='small' disableNewStyle
                                        disabled={x.onChange == null}
                                        onChange={x.onChange == null ? undefined : ((value) => x.onChange!(p.id, value))}
                                        precision={x.fractionDigits}
                                        align={"right"}
                                        text={x.getValue(p.id)} />
                                }
                            </Cell>
                        </React.Fragment>
                    )}
                </TableRow>
                )
            })
            }
        </>
    )
}

type ProjectedActualTableRowProps = {
    company: Company
    opening: boolean
}

let ProjectedActualTableRow = ({ classes, company, opening }: ProjectedActualTableRowProps & MuiProps) => {
    let store = ActualStockContainer.useContainer()

    let findProjected = (productId: Guid) => store.stockInput.projectedQuantitys.find(x => x.productId == productId && x.company == company.code && x.isOpening == opening)?.quantity ?? null
    let findActual = (productId: Guid) => {

        if (opening && !hasFeature('OpeningActualStock'))
            return store.stockInput.values.filter(x => x.productId == productId && x.company == company.code).map(x => x.openingQuantity ?? 0).reduce((acc, current) => acc + current, 0)
        else
            return store.stockInput.values.filter(x => x.productId == productId && x.company == company.code).map(x => x.volume ?? 0).reduce((acc, current) => acc + current, 0)
    }

    return (
        <>
            <TableRow>
                <Cell />
                <Cell />
                <Cell>
                    <Typography className={classes.openingClosing} display="block">{t(tBase + (opening ? 'opening' : 'closing'))}</Typography>
                </Cell>
                {store.availableProducts.map(x =>
                    <React.Fragment key={x.code}>
                        <Cell>
                            <Typography className={classes.projectedActual}>{t(tBase + 'projected')}</Typography>
                        </Cell>
                        <Cell>
                            <Typography className={classes.projectedActual}>{t(tBase + 'actual')}</Typography>
                        </Cell>
                    </React.Fragment>
                )}
            </TableRow>
            <TableRow>
                <Cell />
                <Cell />
                <Cell />
                {store.availableProducts.map(x =>
                    <React.Fragment key={x.code}>
                        <Cell>
                            <NumberField size='small' disableNewStyle
                                disabled={true}
                                allowNegative={true}
                                align={"right"}
                                text={findProjected(x.id)} />
                        </Cell>
                        <Cell>
                            <NumberField size='small' disableNewStyle
                                disabled={true}
                                allowNegative={true}
                                align={"right"}
                                text={findActual(x.id)} />
                        </Cell>
                    </React.Fragment>
                )}
            </TableRow>
        </>
    )
}

function EmptyTableRow() {
    let store = ActualStockContainer.useContainer()

    return (
        <TableRow>
            <Cell />
            <Cell />
            <Cell />
            {
                store.availableProducts.map(x =>
                    <Cell key={x.code + 'emptyCell'} colSpan={2} />
                )
            }
        </TableRow>
    )
}

type CompanyProductDutyStatusTableRowProps = {
    company: Company
    dutyStatus: string
    isCalibrated: boolean
    allocatedQuantity: string | null
    unusableQuantity: string | null
}

function CompanyProductDutyStatusTableRow({ classes, company, dutyStatus, isCalibrated, allocatedQuantity, unusableQuantity }: CompanyProductDutyStatusTableRowProps & MuiProps) {
    let store = ActualStockContainer.useContainer()
    let total = !!allocatedQuantity || !!unusableQuantity

    return (
        <>
            {total &&
                <TableRow>
                    <Cell />
                    <Cell>
                        <Typography className={classes.dutyStatusTypo} display="block">{dutyStatus}</Typography>
                    </Cell>
                    <Cell>{t('stock.label.stocks.total')}</Cell>

                    {store.availableProducts.map((p) => <StockProductCell
                        key={p.code}
                        classes={classes}
                        company={company}
                        dutyStatus={dutyStatus}
                        kind='total'
                        product={p}
                        isCalibrated={isCalibrated}
                        allocatedQuantity={allocatedQuantity} />)}
                </TableRow>
            }

            <TableRow>
                <Cell />
                <Cell>
                    {!total &&
                        <Typography className={classes.dutyStatusTypo} display="block">{dutyStatus}</Typography>
                    }
                </Cell>
                <Cell>
                    {(allocatedQuantity || unusableQuantity) && t('stock.label.dutyStatusStocks')}
                </Cell>
                {store.availableProducts.map((p) => <StockProductCell
                    key={p.code}
                    classes={classes}
                    company={company}
                    dutyStatus={dutyStatus}
                    kind='volume'
                    product={p}
                    isCalibrated={isCalibrated}
                    allocatedQuantity={allocatedQuantity} />)}
            </TableRow>
            {allocatedQuantity &&
                <TableRow>
                    <Cell />
                    <Cell />
                    <Cell>{t(`stock.label.associatedQuantity.${allocatedQuantity}`)}</Cell>

                    {store.availableProducts.map((p) => <StockProductCell
                        key={p.code}
                        classes={classes}
                        company={company}
                        dutyStatus={dutyStatus}
                        kind='allocated'
                        product={p}
                        isCalibrated={isCalibrated}
                        allocatedQuantity={allocatedQuantity} />)}
                </TableRow>
            }
            {unusableQuantity &&
                <TableRow>
                    <Cell />
                    <Cell />
                    <Cell>{t(`stock.label.associatedQuantity.${unusableQuantity}`)}</Cell>

                    {store.availableProducts.map((p) => <StockProductCell
                        key={p.code}
                        classes={classes}
                        company={company}
                        dutyStatus={dutyStatus}
                        kind='unusable'
                        product={p}
                        isCalibrated={isCalibrated}
                        allocatedQuantity={allocatedQuantity} />)}
                </TableRow>
            }
        </>
    )
}

type StockProductCellProps = {
    company: Company
    dutyStatus: string
    kind: 'total' | 'volume' | 'allocated' | 'unusable'
    product: StockProduct
    isCalibrated: boolean
    allocatedQuantity: string | null
}

function StockProductCell({ company, dutyStatus, kind, classes, product, isCalibrated, allocatedQuantity }: StockProductCellProps & MuiProps) {
    let store = ActualStockContainer.useContainer()
    let shouldDisableField = store.siteCompanyProducts.findIndex(x => x.company === company.name
        && x.product === product.code
        && x.dutyStatus === dutyStatus) < 0

    let findStockInputValue = (): StockInputValue | null => {
        let values = store.stockInputValuePerCompany[company.code]
        if (!values) return null
        let index = values.findIndex(x => x.dutyStatus == dutyStatus && x.productId == product.id)
        if (index < 0) return null
        return values[index]
    }
    let stockInputValue = findStockInputValue()

    let safeAddition = (a: number | undefined, b: number | null | undefined) => a == null && b == null ? null : (a ?? 0) + (b ?? 0)

    let volume: number | null = (kind === 'volume' ? stockInputValue?.volume
        : kind === 'allocated' ? stockInputValue?.allocatedQuantity
            : kind === 'unusable' ? stockInputValue?.unusableQuantity
                : kind === 'total' ? safeAddition(stockInputValue?.volume, (allocatedQuantity ? stockInputValue?.allocatedQuantity : null))
                    : null) ?? null

    let createStockInputValue = (): [StockInputValue | null, number] => {
        let index = store.stockInput.values.findIndex(x =>
            x.productId === product.id &&
            x.dutyStatus === dutyStatus &&
            x.company === company.code)

        if (!store.stockInput.values || !store.stockInput.values[index]) return [null, index]
        return [{
            dutyStatus: dutyStatus,
            company: company.code,
            companyName: company.name,
            productId: product.id,
            productCode: product.code,
            isCalibration: store.stockInput.values[index].isCalibration,
            volume: store.stockInput.values[index].volume,
            volumeOverwritten: store.stockInput.values[index].volumeOverwritten,
            allocatedQuantity: store.stockInput.values[index].allocatedQuantity,
            unusableQuantity: store.stockInput.values[index].unusableQuantity,
        }, index]
    }

    let onChangeVolume = (value: number | null) => {
        let [stockInputValue, index] = createStockInputValue()
        if (!stockInputValue) return
        let newValue =
            kind === 'volume' ? { ...stockInputValue, volume: value ?? undefined, volumeOverwritten: (value !== undefined && value !== null) }
                : kind === 'allocated' ? { ...stockInputValue, allocatedQuantity: value ?? undefined }
                    : kind === 'unusable' ? { ...stockInputValue, unusableQuantity: value ?? undefined }
                        : null;
        if (newValue) store.setStockInputValue(index, newValue)
    }

    return (
        <>
            <Cell />
            <Cell>
                {!shouldDisableField &&
                    <NumberField
                        size='small' allowNegative disableNewStyle disabled={isCalibrated || kind === 'total'}
                        onChange={v => { onChangeVolume(v) }}
                        align={"right"}
                        text={volume} />
                }
            </Cell>
        </>
    )
}

let styles = () =>
    createStyles({
        tableBody: {
            backgroundColor: "#fff"
        },
        tableMargin: { margin: '2em 0em 2em 0em' },
        tableHead: { backgroundColor: '#fafafa' },
        cellText: { textAlign: 'center' },
        dutyStatusTypo: { fontWeight: 'bold' },
        capacity: {
            textAlign: 'left',
            minWidth: 'max-content'
        },
        companyName: {
            color: defaultColors.red.main.color,
            fontWeight: 'bold',
        },
        companyNameCalibrated: {
            color: defaultColors.darkBlue.main.color,
            fontWeight: 'bold',
        },
        openingClosing: {
            color: defaultColors.darkBlue.main.color,
            fontWeight: 'bold',
        },
        projectedActual: {
            fontWeight: 'bold'
        },
        checkbox: {
            textAlign: 'center',
            marginBottom: "-10px",
        },
        checkboxChecked: {
            color: defaultColors.red.main.color
        },
        capacityCell: {
            border: '1px solid lightgrey',
            width: '25%',
            padding: 0
        },
        stickyHeader: {
            position: 'sticky',
            top: '11em',
            zIndex: 3
        },
        customSubtitle1: {
            textTransform: "uppercase"
        },
        customSubtitle2: {
            fontSize: "1rem"
        }
    })

export default withStyles(styles, muiOptions)(ActualStockTable)