import { DealListItemBase } from "./dealModels";
import { ExcelCell, ExcelFileContents, ExcelLine } from '../../infrastructure/excelExport'
import { ColumnDescriptor } from "../common/customComponents";

export let exportItems = <T extends DealListItemBase>(columns: ColumnDescriptor<T>[], items: T[], generateExcel: (contents: ExcelFileContents) => void, isPricingPeriodItems: boolean) => {
    if (!items.length) return

    let headerLine = getHeaderLine(columns)
    let itemRows = getItemRows(columns, items)
    let allLines = [headerLine, ...itemRows]
    let importName = isPricingPeriodItems ? 'PricingPeriods' : 'Deals'
    generateExcel({
        filename: `${importName}.xlsx`,
        sheets: [{ name: `${importName}`, lines: allLines }]
    })
}

let getHeaderLine = <T extends DealListItemBase>(columns: ColumnDescriptor<T>[]): ExcelLine => {
    let headerCells: ExcelCell[] = columns.flatMap(x =>
    (x.unit
        ? [{ type: 'string', value: x.name }, { type: 'string', value: x.name + ' Unit' }]
        : [{ type: 'string', value: x.name }]))
    return { cells: headerCells }
}

let getItemRows = <T extends DealListItemBase>(columns: ColumnDescriptor<T>[], items: T[]): ExcelLine[] => {
    return items.map(x =>
    ({
        cells: columns.flatMap(c => c.type?.includes("deal") ? getItemCellColumn(c, x) :
            !!c.unit ? [getItemCell(c, x), getItemUnitCell(c, x)] : [getItemCell(c, x)])
    }))
}

let getItemCell = <T extends DealListItemBase>(column: ColumnDescriptor<T>, item: T): ExcelCell => {
    let getCellType = (rawType: string): 'string' | 'number' | 'comment' => maybeCompatibleType(rawType) ?? 'string'
    let maybeCompatibleType = (type: string): 'string' | 'number' | undefined => type === 'string' || type === 'number'
        ? type
        : undefined
    let value = column.value ? column.value!(item) : ''
    let valueType = typeof value
    let cellType = getCellType(valueType)
    let isTypeCompatibleWithExcelCell = valueType === cellType
    return {
        type: cellType,
        value: isTypeCompatibleWithExcelCell ? value?.toString() ?? '' : ''
    }
}

let getItemUnitCell = <T extends DealListItemBase>(column: ColumnDescriptor<T>, item: T): ExcelCell =>
    ({ type: 'string', value: column.unit ? column.unit(item) ?? '' : '' })

let getItemCellColumn = <T extends DealListItemBase>(column: ColumnDescriptor<T>, item: T): ExcelCell =>
    ({ type: 'comment', value: `${column.type}-${item.id}` })